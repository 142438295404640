<script>
import { inject, computed, watchEffect } from "vue";
import feather from "feather-icons";

export default {
  setup() {
    const themeState = inject("themeState");

    // Watch for changes and refresh Feather icons
    watchEffect(() => {
      feather.replace();
    });

    return {
      themeState,
      toggleTheme() {
        themeState.toggleTheme();
      },
    };
  },
};
</script>

<template>
  <a href="#" @click.prevent="toggleTheme" aria-label="Theme Switcher">
  
    <svg v-if="themeState.theme === 'light'" class="p-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M10 7a7 7 0 0 0 12 4.9v.1c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2h.1A6.98 6.98 0 0 0 10 7m-6 5a8 8 0 0 0 15.062 3.762A9 9 0 0 1 8.238 4.938A8 8 0 0 0 4 12"/></svg>
    <svg v-else class="p-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M12 18a6 6 0 1 1 0-12a6 6 0 0 1 0 12m0-2a4 4 0 1 0 0-8a4 4 0 0 0 0 8M11 2h2v3h-2zm-9 9h3v2H2zm17 0h3v2h-3zm-8 8h2v3h-2zm7.621-15l1.415 1.414l-2.122 2.122L16.5 6.12zM16.5 17.414L17.914 16l2.122 2.121l-1.415 1.415zM6.121 16l1.415 1.414l-2.122 2.122L4 18.12zM4 5.414L5.414 4l2.122 2.121L6.12 7.536z"/></svg>
    
  </a>
</template>
