const projects_items = [
  {
    id: 4,
    title: "My Life Plans - Jamaican Client Project",
    category: "Raw PHP",
    tags: ["PHP", "Git", "Version Control", "Client Project"],
    live_link: "https://mylifeplans.xyz/",
    github_link: "",
    color: "#6ac4c5",
    date: "23 March 2021",
    desc: `
      <h2>Project Overview</h2>

      <div class='py-4'>
       <strong>Website:</strong> <a href='https://mylifeplans.xyz/' target='_blank'>My Life Plans</a><br/>
       <strong>Platform:</strong> Raw PHP<br>
       <strong>Client:</strong> My Life Plans - Jamaica
      </div>
         
      <h2>My Role</h2>
      <ul>
        <li>Designed and developed fully responsive HTML pages for all screens.</li>
        <li>Implemented core functionality and interactive features using raw PHP.</li>
        <li>Collaborated with the team using Git for version control and seamless teamwork.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Personalized Planning Tools:</strong> Enables users to set and track personal goals.</li>
        <li><strong>Resource Library:</strong> Offers articles, tips, and guides for personal development.</li>
        <li><strong>User-Friendly Interface:</strong> Clean, intuitive, and easy-to-navigate design.</li>
        <li><strong>Interactive Features:</strong> Engages users with tools for organizing and improving their lives.</li>
      </ul>
  
      <h2>Outcome</h2>
      <p>Successfully delivered a robust, user-friendly platform tailored to the client’s vision. The website supports users in achieving personal growth through custom tools and resources.</p>
    `,
    img: require("@/assets/images/projects/my-life-plans.webp"),
  },

  {
    id: 5,
    title: "Pinbadgesrus - First Fiverr Order :)",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://pinbadgesrus.co.uk/",
    github_link: "",
    color: "#00FFFC",
    date: "25 March 2021",
    desc: `
      <h2>Project Overview</h2>

      <div class='py-4'>
       <strong>Website:</strong> <a href='https://pinbadgesrus.co.uk/' target='_blank'>Pinbadgesrus</a><br/>
       <strong>Platform:</strong> WordPress<br>
       <strong>Client:</strong> A client from UK
      </div>

      <p>Pinbadgesrus was my first Fiverr project, making it a special milestone. The project involved building a vibrant e-commerce platform for custom pin badges. I worked closely with the client to meet their design and functionality requirements, creating an engaging shopping experience for their customers.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Selected and customized a WordPress theme to match the client’s vision.</li>
        <li>Designed and implemented features for a user-friendly e-commerce experience.</li>
        <li>Integrated payment gateways and ensured secure transactions.</li>
        <li>Optimized the site for performance, speed, and search engine visibility.</li>
        <li>Provided ongoing support and maintenance for the client’s website.</li> 
        <li>Trained the client on WordPress usage, empowering them to manage the site independently.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Showcase:</strong> High-quality product listings with detailed descriptions.</li>
        <li><strong>Streamlined User Experience:</strong> Easy navigation and intuitive search functionality.</li>
        <li><strong>Secure Checkout:</strong> Integrated reliable payment gateways.</li>
        <li><strong>Customer Engagement:</strong> Included reviews and ratings to foster trust and community.</li>
        <li><strong>Client Support:</strong> Dedicated contact options for quick assistance.</li>
      </ul>
    `,
    img: require("@/assets/images/projects/pinbadgesrus.webp"),
  },

  {
    id: 6,
    title: "Zulufins - A Webshop for Selling Fins",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://zulufins.nl/",
    github_link: "",
    color: "#89b1bb",
    date: "01 May 2021",
    desc: `
      <h2>Project Overview</h2>
  
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://zulufins.nl/' target='_blank'>Zulufins</a><br/>
        <strong>Platform:</strong> WordPress<br>
        <strong>Client:</strong> A business owner from the Netherlands
      </div>
  
      <p>My client, based in the Netherlands, had been successfully selling fins locally and saw potential in expanding online. They approached me to help transition their business to the digital world. I guided them through what was needed, and together, we launched the Zulufins webshop. Surprisingly, the move online boosted their sales by more than 50%!</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Chose and customized a WordPress theme that aligned perfectly with the client's aesthetic goals.</li>
        <li>Developed a user-friendly e-commerce platform with intuitive features that enhance the shopping experience.</li>
        <li>Integrated multiple payment gateways to ensure transaction security.</li>
        <li>Optimized the website for performance, loading speed, and visibility on search engines.</li>
        <li>Provided continuous support and maintenance, ensuring the site remained up-to-date and secure.</li> 
        <li>Trained the client on how to use WordPress, enabling them to manage their site with confidence.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Showcase:</strong> High-quality product listings complete with detailed descriptions and crisp images.</li>
        <li><strong>Streamlined User Experience:</strong> Effortlessly navigate through the site with a clear and logical layout.</li>
        <li><strong>Secure Checkout:</strong> Reliable payment solutions that safeguard customer transactions.</li>
        <li><strong>Customer Engagement:</strong> Engaging features like reviews and ratings that build trust and foster a sense of community.</li>
        <li><strong>Client Support:</strong> Quick and easy access to support for an uninterrupted shopping experience.</li>
      </ul>
    `,
    img: require("@/assets/images/projects/zulufins.nl.webp"),
  },

  {
    id: 9,
    title: "Polio - HTML to WordPress",
    category: "WordPress Development",
    tags: [
      "WordPress",
      "Kirki Framework",
      "ACF",
      "WordPress Theme Development",
    ],
    live_link: "https://polio.techviewing.com/",
    github_link: "",
    color: "#89b1bb",
    date: "04 August 2021",
    desc: `
    <h2>Project Overview</h2>
    <div class='py-4'>
      <strong>Website:</strong> <a href='https://polio.techviewing.com/' target='_blank'>Polio</a><br>
      <strong>Platform:</strong> WordPress
    </div>
    <p>Given a static HTML template by the client, my task was to transform it into a dynamic, fully functional WordPress website. This practice project was executed using the Kirki Customizer Framework along with Advanced Custom Fields (ACF), focusing on creating a custom theme that maintains the original's design while elevating its functionality.</p>

    <h2>My Role</h2>
    <ul>
      <li>Developed a custom WordPress theme to mirror the provided HTML template.</li>
      <li>Utilized Kirki Framework to enhance theme customization, enabling detailed control over site aesthetics and functions.</li>
      <li>Incorporated Advanced Custom Fields (ACF) for robust content management capabilities.</li>
      <li>Ensured the site was responsive, making it accessible and visually consistent across different devices.</li>
      <li>Focused on optimizing the user interface for an engaging and intuitive user experience.</li>
    </ul>

    <h2>Key Features</h2>
    <ul>
      <li><strong>Custom WordPress Theme:</strong> Tailored specifically to retain the look and feel of the original HTML design while enhancing interactivity.</li>
      <li><strong>Kirki Customizer Framework:</strong> Empowered with advanced customization tools for flexible theme options and settings.</li>
      <li><strong>Responsive Design:</strong> Fully adaptable to all screen sizes, ensuring a seamless viewing experience on tablets, phones, and desktops.</li>
      <li><strong>Enhanced User Experience:</strong> Upgraded interaction design elements to make the website more engaging and easier to navigate.</li>
    </ul>

    `,
    img: require("@/assets/images/projects/polio.webp"),
  },

  {
    id: 16,
    title: "Pastpost v1 - Figma to WordPress",
    category: "WordPress Development",
    tags: ["WordPress", "Elementor Widget Development", "Client Project"],
    live_link: "https://pastpost.com/",
    github_link: "",
    color: "#f5f1ee",
    date: "01 November 2022",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://pastpost.com/' target='_blank'>Pastpost</a><br/>
        <strong>Platform:</strong> WordPress<br>
        <strong>Client:</strong> Pastpost
      </div>
      <p>Pastpost v1 showcases the seamless transformation of Figma designs into a dynamic WordPress site, complete with custom Elementor widgets and plugins for a tailored user experience.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Converted Figma designs into a fully functional WordPress theme using Elementor.</li>
        <li>Developed custom Elementor widgets to precisely match the Figma designs.</li>
        <li>Created multiple plugins to enhance the website's functionality and user engagement.</li>
        <li>Ensured the site was responsive and provided a consistent experience across all devices.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Custom Elementor Widgets:</strong> Designed to extend the functionality and maintain the aesthetics of the original design.</li>
        <li><strong>Plugin Development:</strong> Developed custom plugins for content management and additional site features.</li>
        <li><strong>Responsive Design:</strong> Optimized for performance and usability on desktops, tablets, and smartphones.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Successfully translated detailed Figma designs into a WordPress environment, enhancing the site’s functionality without compromising on design. The integration of custom Elementor widgets and plugins has not only met but exceeded client expectations, proving the effectiveness of tailored solutions in web development.</p>
  
      <pre class="bg-gray-200"><code >
        Design Inspiration (Figma): <a href='https://www.figma.com/file/04Eiq4vkRiWoomVgO95fDq/Past-Post-Design?type=design&node-id=0%3A1&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
        Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/pastpost-elementor-widgets-custom-design' target='_blank'>View Repository</a>
        Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-elementor-plugin' target='_blank'>View Repository</a>
        Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-companion-plugin' target='_blank'>View Repository</a>
      </code></pre>
    `,
    img: require("@/assets/images/projects/pastpost_v1.webp"),
  },
  {
    id: 16.1,
    title: "Pastpost v2 - Figma to Wordpress",
    category: "WordPress Development",
    tags: ["WordPress", "Elementor Widget Development", "Client Project"],
    live_link: "https://pastpost.com/",
    github_link: "",
    color: "#f5f1ee",
    date: "20 April 2023",
    desc: `
    <h2>Project Overview</h2>
    <div class='py-4'>
      <strong>Website:</strong> <a href='https://pastpost.com/' target='_blank'>Pastpost v2</a><br/>
      <strong>Platform:</strong> WordPress<br>
      <strong>Client:</strong> Pastpost
    </div>
    <p>Building on the success of our initial project, Pastpost v2 aimed to further transform Figma designs into a more dynamic and feature-rich WordPress site. This update brought new design enhancements and advanced Elementor widget capabilities to deliver an even more engaging user experience.</p>

    <h2>My Role</h2>
    <ul>
      <li>Upgraded the existing WordPress site to incorporate new Figma design elements.</li>
      <li>Developed new custom Elementor widgets to extend functionality and interactivity.</li>
      <li>Enhanced the site's responsiveness and user interface to improve accessibility and usability.</li>
      <li>Implemented advanced features to keep the site at the forefront of web development standards.</li>
    </ul>

    <h2>Key Features</h2>
    <ul>
      <li><strong>Updated Elementor Widgets:</strong> New custom widgets designed to integrate seamlessly with the updated site aesthetics and functionality.</li>
      <li><strong>Improved User Interface:</strong> A revamped design that responds well across all devices, providing a superior browsing experience.</li>
      <li><strong>Advanced Functionalities:</strong> Incorporation of the latest web technologies to ensure the site remains cutting-edge and meets evolving user demands.</li>
    </ul>

    <h2>Outcome and Impact</h2>
    <p>The overhaul of the Pastpost website not only met but exceeded client expectations, showcasing the powerful combination of WordPress and Elementor in creating adaptable and user-centric websites. The project stands as a benchmark in modern web design, significantly enhancing user engagement and satisfaction.</p>

    <pre class="bg-gray-200"><code>
      Design Inspiration (Figma): <a href='https://www.figma.com/file/04Eiq4vkRiWoomVgO95fDq/Past-Post-Design?type=design&node-id=0%3A1&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
      Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/pastpost-elementor-widgets-custom-design' target='_blank'>View Repository</a>
      Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-elementor-plugin' target='_blank'>View Repository</a>
      Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-companion-plugin' target='_blank'>View Repository</a>
    </code></pre>
  `,
    img: require("@/assets/images/projects/pastpost_v2.webp"),
  },

  {
    id: 19,
    title: "Xingfa - Figma to WordPress",
    category: "WordPress Development",
    tags: ["WordPress", "Client Project"],
    live_link: "https://jf-facadeglass.com/",
    github_link: "",
    color: "#0b83ba",
    date: "27 November 2022",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://jf-facadeglass.com/' target='_blank'>Xingfa</a><br/>
        <strong>Platform:</strong> WordPress<br>
        <strong>Client:</strong> Xingfa
      </div>
      <p>The Xingfa project was a meticulous endeavor to transform Figma design wireframes into a fully functional WordPress website, using custom Elementor widgets and plugins to deliver a seamless and engaging user experience tailored to the manufacturing industry.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Transformed Figma wireframes into a dynamic and responsive WordPress theme using Elementor.</li>
        <li>Developed custom Elementor widgets to ensure the website matched the detailed design specifications.</li>
        <li>Integrated custom plugins to enhance the site's functionality and meet the specific needs of the manufacturing sector.</li>
        <li>Focused on creating a user-friendly interface that reflects Xingfa's standards of excellence and efficiency.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Custom Elementor Widgets:</strong> Specifically developed to match the Figma designs, enhancing interactivity and visual fidelity.</li>
        <li><strong>Responsive Design:</strong> The site is optimized for all devices, ensuring a consistent and accessible user experience across all platforms.</li>
        <li><strong>Advanced Functionality:</strong> Includes specialized plugins that extend the website's capabilities, supporting Xingfa’s business operations.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The successful launch of the Xingfa website not only fulfilled the project's objectives but also set a new standard in marrying design with functionality, showcasing the potential of WordPress and Elementor to create a modern, effective, and user-centric online presence for the manufacturing industry.</p>
  
      <pre class="bg-gray-200"><code >
        Design Inspiration (Figma): <a href='https://www.figma.com/file/Yeuedbc1vJy4IkHmObSjni/xing-fa-wirefram?type=design&node-id=0%3A1&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
        Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/xingfa-elementor-widgets-custom-design' target='_blank'>View Repository</a>
        Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-xingfa-elementor-plugin' target='_blank'>View Repository</a>
        Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-xingfa-companion-plugin' target='_blank'>View Repository</a>
      </code></pre>
    `,
    img: require("@/assets/images/projects/xingfa.webp"),
  },

  {
    id: 21,
    title: "BoxoSox - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://boxosox.club/",
    github_link: "",
    color: "#d4bc26",
    date: "23 July 2022",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://boxosox.club/' target='_blank'>BoxoSox</a><br/>
        <strong>Platform:</strong> WordPress<br>
        <strong>Client:</strong> BoxoSox
      </div>
      <p>At BoxoSox, a Jamaican fashion leader in premium socks, I was tasked with creating a professionally designed website that not only showcases their products with high-quality photography but also supports complex e-commerce functionalities. This includes an integrated affiliate system, advanced WooCommerce conditional features for better user experiences, and secure payment solutions.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Professionally designed the site to enhance the brand’s visual appeal and usability.</li>
        <li>Implemented WooCommerce with advanced conditional logic to streamline shopping experiences based on user behavior and preferences.</li>
        <li>Integrated secure payment gateways to facilitate easy and safe transactions.</li>
        <li>Developed an affiliate marketing program to expand the brand’s reach and increase sales.</li>
        <li>Providing ongoing maintenance and updates to ensure the website evolves with the business needs and latest web standards.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About BoxoSox:</strong> Detailed brand philosophy emphasizing quality and exclusivity.</li>
        <li><strong>Affiliate Programs:</strong> Robust affiliate system to maximize global distribution and sales.</li>
        <li><strong>Celebrate Jamaica:</strong> Exclusive collection celebrating the vibrant Jamaican culture and style.</li>
        <li><strong>Shop Collections:</strong> User-friendly shopping environment with a variety of socks displayed in a stylish and accessible manner.</li>
        <li><strong>Why Join BoxoSox.club?:</strong> Encourages users to become part of the community for special offers and updates.</li>
        <li><strong>Contact Us:</strong> Easy access for customer inquiries with a responsive contact system.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The launch of the BoxoSox website has successfully driven the brand to new heights in the online fashion market. Through my continuous support and development, the site effectively attracts and retains customers by marrying sophisticated design with powerful e-commerce capabilities, significantly increasing sales and expanding the customer base.</p>
    `,
    img: require("@/assets/images/projects/boxocox.webp"),
  },

  {
    id: 24,
    title: "Chamber Hydro Farm - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://chambershydrofarm.com/",
    github_link: "",
    color: "#7aa2ab",
    date: "27 May 2022",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://chambershydrofarm.com/' target='_blank'>Chamber Hydro Farm</a><br/>
        <strong>Platform:</strong> WordPress with WooCommerce<br>
        <strong>Client:</strong> Chamber Hydro Farm
      </div>
      <p>Chamber Hydro Farm, spearheaded by Ricardo Chambers, is pioneering new agriculture techniques in Jamaica. This project delivered a modern e-commerce site that mirrors the farm's innovative approach to propagation, containerized farming, and hydroponics.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Designed and developed the website with a focus on user experience, ensuring it was intuitive and responsive.</li>
        <li>Integrated WooCommerce to provide a seamless e-commerce experience tailored to the farm’s needs.</li>
        <li>Implemented features to showcase the farm’s advanced agricultural techniques and products.</li>
        <li>Enhanced the site’s visual appeal through a clean and modern design that reflects the farm's commitment to sustainability and innovation.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Home Page:</strong> Welcomes visitors with an introduction to the farm’s mission and offerings.</li>
        <li><strong>About Us:</strong> Shares the history and values behind Chamber Hydro Farm.</li>
        <li><strong>Gallery:</strong> Displays the farm’s innovative techniques and lush greenery.</li>
        <li><strong>Our Offerings:</strong> Details the range of products and services available.</li>
        <li><strong>Visit the Farm:</strong> Encourages on-site visits with information on what visitors can expect.</li>
        <li><strong>Shop Online:</strong> Enables online purchases with a user-friendly e-commerce setup.</li>
        <li><strong>Contact Us:</strong> Offers multiple ways for customers to engage with the farm.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since launching, the website has significantly boosted Chamber Hydro Farm’s online visibility and market reach, becoming a key tool in their ongoing efforts to modernize agriculture in Jamaica. The site effectively communicates the brand’s story and has been instrumental in attracting a wider audience interested in sustainable agricultural practices.</p>
    `,
    img: require("@/assets/images/projects/Chambers-Hydrofarm-Ecommerce.webp"),
  },

  {
    id: 30,
    title:
      "Pharmacy Management System - A Complete package for web development",
    category: "Raw PHP, Laravel, React JS, React-Laravel-Full-Stack",
    tags: ["Raw PHP", "Laravel", "React", "Full Stack"],
    live_link: "",
    github_link: "",
    color: "#4e73df",
    date: "01 September 2022",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-2'>
        <strong>Platform:</strong>
        <ul>
          <li>Raw PHP</li>
          <li>Laravel</li>
          <li>React</li>
          <li>React-Laravel-Full-Stack</li>
        </ul>
        <br>
      </div>
  
      <h2>Project Evolution</h2>
      <ul>
        <li>2020: Raw HTML/CSS - <a href='https://github.com/md-arif-islam/admin-dashboard-fontend' target='_blank'>View on GitHub</a></li>
        <li>Conversion to Functional Application with Raw PHP - <a href='https://github.com/md-arif-islam/pharmacy-management-system-with-php-mysql' target='_blank'>View on GitHub</a></li>
        <li>Integration with Laravel - <a href='https://github.com/md-arif-islam/pharmacy-management-system-with-laravel' target='_blank'>View on GitHub</a></li>
        <li>Upgrade to React JS - <a href='https://github.com/md-arif-islam/admin-dashboard-with-react' target='_blank'>View on GitHub</a></li>
        <li>Full Stack with React JS + Laravel - <a href='https://github.com/md-arif-islam/react-laravel-full-stack' target='_blank'>View on GitHub</a></li>
      </ul>
  
      <h2>My Role</h2>
      <ul>
        <li>Designed and developed the initial HTML/CSS layout.</li>
        <li>Implemented core functionality using Raw PHP.</li>
        <li>Integrated Laravel for backend enhancements.</li>
        <li>Upgraded the frontend to React for a dynamic user experience.</li>
        <li>Developed a full-stack solution combining React and Laravel.</li>
      </ul>

      <div class='embed-container'>
        <iframe src='https://player.vimeo.com/video/890158277?h=b30da7a9ad' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
      </div>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Admin Dashboard:</strong> Comprehensive management of pharmacists, managers, and salesmen.</li>
        <li><strong>Role-Based Access:</strong> Different functionalities based on user roles.</li>
        <li><strong>Profile Management:</strong> Users can modify profiles and change passwords.</li>
        <li><strong>Interactive UI:</strong> Clean and intuitive interface for easy navigation.</li>
      </ul>

      <div class='embed-container'>
        <iframe src='https://player.vimeo.com/video/890160352?h=722f25472c' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
      </div>
  
      <h2>Outcome</h2>
      <p>Successfully developed a robust, full-stack Pharmacy Management System that evolved from a simple HTML/CSS project to a comprehensive solution using Raw PHP, Laravel, and React.</p>
  
      <h2>Login Information</h2>
      <p>Login as different roles to experience the system's multi-faceted interface.</p>
      <pre><code class="language-html">
      <b>Admin Login:</b>
      Email: arif@islam.com
      Password: 1234
      Role: Admin
      </code></pre>
  
      <h2>Explore More</h2>
      <p>Discover the full potential of this Pharmacy Management System by delving into its code repositories and watching the demo videos.</p>
  
      
    
    `,
    img: require("@/assets/images/projects/pharmacy-management-system.webp"),
  },

  {
    id: 30.1,
    title: "MT4Gadgets - GeneratePress - Ecommerce Digital Product",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Digital Products"],
    live_link: "https://mt4gadgets.com/",
    github_link: "",
    color: "#8c7ae6",
    date: "20 March 2023",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://mt4gadgets.com/' target='_blank'>MT4Gadgets</a><br/>
        <strong>Platform:</strong> WordPress with GeneratePress<br>
        <strong>Client:</strong> MT4Gadgets
      </div>
      <p>MT4Gadgets serves as a premier online destination for digital products, where I employed GeneratePress for the first time to create a sleek, responsive e-commerce site. This project focused on delivering a high-performance digital marketplace with a user-friendly shopping environment for an array of digital tools and gadgets.</p>
  
      <h2>My Role</h2>
      <ul>
        <li>Customized GeneratePress theme to fit the unique requirements of selling digital products online.</li>
        <li>Integrated advanced e-commerce functionalities to support digital transactions and secure payment systems.</li>
        <li>Designed the site to ensure a minimalistic and clean user interface that enhances product visibility and ease of access.</li>
        <li>Implemented responsive design features making the site fully functional across all devices and platforms.</li>
        <li>Managed the setup and deployment of the site, ensuring optimal performance and user satisfaction.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Display:</strong> Effectively showcases digital gadgets with detailed descriptions and high-quality images.</li>
        <li><strong>Secure Checkout:</strong> Provides a safe and hassle-free checkout process that reassures customers.</li>
        <li><strong>Customer Reviews:</strong> Enables customers to provide feedback, fostering trust and improving product offerings.</li>
        <li><strong>Search Functionality:</strong> Allows users to easily locate specific products through an efficient search tool.</li>
        <li><strong>Responsive Design:</strong> Delivers a consistent experience across various devices, ensuring accessibility and convenience.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>My work on the MT4Gadgets project significantly enhanced the client’s sales and customer engagement. The integration of GeneratePress with the site’s digital product offerings led to a marked improvement in performance and sales, much to the client's delight.</p>
    `,
    img: require("@/assets/images/projects/mt4gadgets-ecommerce-digital.webp"),
  },

  {
    id: 34,
    title: "Money Manager Vue App",
    category: "Vue JS",
    tags: ["Vue JS", "Tailwind", "Firebase", "Pinia"],
    live_link: "https://money-manager-vue-app.arifislam.me",
    github_link: "https://github.com/md-arif-islam/money-manager-vue-app",
    color: "#3547c9",
    date: "20 October 2023",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://money-manager-vue-app.arifislam.me' target='_blank'>Money Manager Vue App</a><br/>
        <strong>GitHub:</strong> <a href='https://github.com/md-arif-islam/money-manager-vue-app' target='_blank'>View Repository</a><br>
        <strong>Platform:</strong> Web<br>
        <strong>Framework:</strong> Vue.js<br>
      </div>
      <p>The Money Manager Vue App is an advanced tool designed to simplify personal finance management. Built with Vue.js, this app provides a responsive and user-friendly environment for tracking finances, featuring robust authentication and real-time data visualization.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Transaction Management:</strong> Users can easily add, categorize, and review their income and expenses.</li>
        <li><strong>Account Customization:</strong> Flexible control over financial categories and account settings.</li>
        <li><strong>Secure Authentication:</strong> Options for email and Google sign-in ensure safe access.</li>
        <li><strong>Dynamic Dashboard:</strong> Offers a comprehensive view of financial status at a glance.</li>
      </ul>
  
      <img src='/assets/images/projects/money-manager-vue-app/dashboard.png")}' alt='Dashboard View of Money Manager Vue App'>
  
      <h2>Getting Started</h2>
      <ol>
        <li>Register for an account using email or Google.</li>
        <li>Customize your financial settings by defining categories and accounts.</li>
        <li>Start logging your transactions to keep track of spending and income.</li>
        <li>Refer to the dashboard for a summarized financial overview.</li>
      </ol>
  
      <h2>Technical Details</h2>
      <p>The application leverages Vue.js for frontend operations, integrating Firebase for backend services, and Pinia for efficient state management. Tailwind CSS ensures a sleek, modern look, complemented by FontAwesome icons and Vuelidate validation.</p>
      
      <h3>Dependencies</h3>
      <ul>
        <li>Vue.js: Core framework providing reactive components.</li>
        <li>Firebase: Backend database and authentication.</li>
        <li>Pinia: State management.</li>
        <li>Tailwind CSS: Utility-first styling.</li>
        <li>Vuelidate: Form validation to enhance user input reliability.</li>
        <li>FontAwesome: Adds aesthetic appeal with iconography.</li>
      </ul>
  
      <h3>Development Tools</h3>
      <p>Developed using Vite, the application benefits from fast build times and efficient asset management, with Autoprefixer and PostCSS optimizing CSS compatibility and performance.</p>
      
      <h4>Version Information</h4>
      <p>Currently at version 1.0.0, the app continues to evolve with ongoing updates and feature enhancements.</p>
      `,
    img: require("@/assets/images/projects/money-manager-vue-app.webp"),
  },

  {
    id: 35,
    title: "Product Research AI - SaaS AI Website",
    category: "WordPress Development",
    tags: [
      "WordPress",
      "Beaver Builder",
      "AI Integration",
      "SaaS",
      "Subscription",
    ],
    live_link: "https://productresearch.ai/",
    github_link: "",
    color: "#4a69bd",
    date: "28 December 2023",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://productresearch.ai/' target='_blank'>ProductResearch.ai</a><br/>
        <strong>Platform:</strong> WordPress with custom Beaver Builder modules<br>
      </div>
      <p>Product Research AI is a sophisticated SaaS platform that leverages AI to transform e-commerce product research. This platform integrates seamlessly with OpenAI to deliver innovative, AI-powered tools for e-commerce success.</p>
      
      <h2>SaaS Concept</h2>
      <p>This project utilizes a SaaS model to provide subscription-based access to tools that enhance e-commerce operations, emphasizing scalability, continuous improvement, and real-time data processing.</p>
  
      <h2>My Role</h2>
      <p>As the developer, I was tasked with:</p>
    <ul>
        <li>Designing the full website with a SaaS model</li>
        <li>Implement payment gateway for subscription-based services</li>
        <li>Developing custom Beaver Builder modules to enhance the platform's functionality, tailored specifically for AI integrations.</li>
        <li>Integrating OpenAI API as a custom Beaver Builder addon, enabling advanced AI features</li>
        <li>Implementing server-side rendered (SSR) content to guarantee optimal performance and real-time updates, ensuring a seamless user experience.</li>
      </ul>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>AI-Driven Product Research:</strong> Utilizes OpenAI to perform deep and insightful product analysis.</li>
        <li><strong>KPI Calculator:</strong> Automatically computes key performance indicators to aid strategic planning.</li>
        <li><strong>Product Copy Generation:</strong> AI-powered tool to generate compelling product descriptions (Coming Soon).</li>
        <li><strong>Customer Persona Development:</strong> Sophisticated AI to craft detailed customer profiles (Upcoming Feature).</li>
        <li><strong>AI-Enhanced Facebook Ad Copy:</strong> Generates optimized ad content for social media marketing (Future Addition).</li>
        <li><strong>Niche Ideas, Winning Product Generator, and Google Ad Copy:</strong> Provides tools to find niche ideas, discover winning products, and create Google ad copy.</li>
        <li><strong>Problem/Solution and Barrier Solver:</strong> Features to address common e-commerce challenges (Coming Soon).</li>
        <li><strong>Profit & Cost Tracking:</strong> Tools to monitor and manage finances effectively (Coming Soon).</li>
        <li><strong>Telegram Community:</strong> A platform for users to engage and share insights.</li>
        <li><strong>Manage Subscription:</strong> Allows users to manage their SaaS subscriptions seamlessly.</li>
      </ul>

      <h2>Screenshots</h2>
      <div class='screenshots'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-1.png' alt='Dashboard Overview'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-2.png' alt='KPI Calculator Interface'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-3.png' alt='Product Research Tool'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-4.png' alt='Customer Persona Builder'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-5.png' alt='Facebook Ad Creator'>
          <img src='/assets/images/projects/productresearchai/product-research-ai-6.png' alt='Product Description Generator'>
      </div>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, Product Research AI has significantly enhanced the efficiency and productivity of e-commerce businesses, providing powerful AI tools that improve decision-making and operational efficiencies, leading to increased sales.</p>
    `,
    img: require("@/assets/images/projects/product-research-ai-sass.webp"),
  },

  {
    id: 38,
    title: "Teermine Tool",
    category: "Raw PHP",
    tags: ["AI", "PHP", "API", "Web Automation"],
    live_link: "https://fashmedien.onlinewithyou.nl/",
    github_link: "",
    color: "#e7c4d6",
    date: "20 March 2024",
    desc: `
      <h2>Project Overview</h2>
      <div class='py-4'>
        <strong>Website:</strong> <a href='https://fashmedien.onlinewithyou.nl/' target='_blank'>Teermine Tool</a><br/>
        <strong>Platform:</strong> AI with PHP<br>
      </div>
      <p>Teermine Tool is a sophisticated web-based dashboard designed to automate and streamline the management of event notifications sent via email. This tool intelligently filters event data, utilizes geolocation APIs to enrich event details, and integrates advanced AI processing to organize and validate the information before database insertion.</p>
      
      <h2>Technical Challenge and Solution</h2>
      <p>Despite appearing as a straightforward dashboard, Teermine Tool performs complex operations behind the scenes. Users submit emails containing various event types, which are then processed to extract relevant details. The tool leverages AI to analyze and categorize event information, uses geolocation APIs to pinpoint event locations, and subsequently pushes this refined data into the Teermine database.</p>

      <h2>My Role</h2>
      <p>Over a rigorous two-month development period, I was responsible for:</p>
      <ul>
        <li>Designing and implementing the AI algorithms that categorize and validate event data.</li>
        <li>Integrating multiple APIs, including geolocation services, to enhance event details automatically.</li>
        <li>Developing a robust backend in PHP that handles complex data processing and API integration seamlessly.</li>
        <li>Creating an admin panel that allows for manual oversight and final confirmation before data publication.</li>
        <li>Ensuring the system's capability to interact with external APIs to publish events on a Drupal website upon admin approval.</li>
      </ul>

      <h2>Key Features</h2>
      <ul>
        <li><strong>AI-driven Event Filtering:</strong> Automates the extraction and categorization of event details from emails.</li>
        <li><strong>Geolocation Enrichment:</strong> Enhances event data with precise location details.</li>
        <li><strong>Multi-API Integration:</strong> Connects with external systems to expand functionality and data dissemination.</li>
        <li><strong>Admin Control Panel:</strong> Provides tools for event verification and publication control.</li>
      </ul>

      
      <h2>Outcome and Impact</h2>
      <p>The deployment of the Teermine Tool has revolutionized the way event data is handled, significantly reducing manual labor and improving the accuracy and timeliness of event publications on affiliated Drupal platforms. This tool has not only enhanced operational efficiency but also ensured that event details are reliably updated and managed.</p>
    `,
    img: require("@/assets/images/projects/teermine.webp"),
  },
  {
    id: 40,
    title: "NapTech Games - Company's Parent Website",
    category: "Laravel",
    tags: ["Laravel", "Speed Optimization", "AI Content"],
    live_link: "https://naptechgames.com/",
    github_link: "",
    color: "#008a00",
    date: "01 January 2023 - ∞",
    desc: `
      <h2>Project Overview</h2>
      <p>NapTechgames serves as the cornerstone for a leading gaming company, offering an extensive array of games and resources to a global audience. When I joined the company, the website was significantly underperforming in speed, even with the use of paid Cloudflare services.</p>
      
      <h2>Challenges and Solutions</h2>
      <p>Upon diagnosing the performance issues, I discovered that image load times were a major bottleneck due to the site hosting over 45,000 images. My solution involved:</p>
      <ul>
        <li>Implementing advanced image optimization techniques using Laravel to significantly reduce file sizes without compromising quality.</li>
        <li>Refactoring backend code and database queries to enhance overall site responsiveness and efficiency.</li>
        <li>Integrating caching mechanisms that further decreased load times and server demand.</li>
      </ul>
      
      <h2>My Role</h2>
      <p>As a Laravel developer, my responsibilities extended beyond just optimizing the site:</p>
      <ul>
        <li>Developing and maintaining APIs for Android and iOS applications, ensuring seamless game data synchronization across platforms.</li>
        <li>Providing game data to partner companies via robust APIs, facilitating a wider distribution and accessibility of games.</li>
        <li>Generating engaging and fresh game content for over 45,000 games using AI-driven tools, keeping the platform vibrant and updated.</li>
        <li>Regularly updating the site’s security measures to safeguard against new vulnerabilities and ensuring compliance with the latest safety standards.</li>
      </ul>

    

      <h2>Outcome and Impact</h2>
      <p>The enhancements I implemented led to a 60% improvement in site speed, drastically improving user experience and engagement. The website now supports high traffic volumes with optimized performance and continues to serve as a dynamic platform for gaming enthusiasts worldwide.</p>
    `,
    img: require("@/assets/images/projects/naptechgames.webp"),
  },
  {
    id: 42,
    title: "Tournament Xoss Games",
    category: "Laravel",
    tags: ["DOB Payment Gateway", "Laravel", "Management System"],
    live_link: "https://tournament.xoss.games/",
    github_link: "",
    color: "#808ae4",
    date: "01 January 2023 - ∞",
    desc: `
      <h2>Project Overview</h2>
      <p>Tournament Xoss Games is a specialized platform designed for managing gaming tournaments. My role involves overseeing the maintenance and continual improvement of the tournament management system, ensuring it operates smoothly and meets the high standards expected by our users.</p>

      <h2>Responsibilities</h2>
      <p>My responsibilities encompass maintaining system stability and implementing updates:</p>
      <ul>
        <li><strong>System Management:</strong> Regular updates and maintenance of the tournament system to ensure optimal performance and uptime.</li>
        <li><strong>Bug Resolution:</strong> Proactively identifying and resolving issues to prevent disruptions during tournaments.</li>
        <li><strong>Feature Updates:</strong> Implementing new features and updates as required, improving system functionality and user experience.</li>
         <li><strong>Enhanced Tournament Management:</strong> A full-featured backend to manage tournaments, including scheduling, participant tracking, and real-time updates.</li>
          <li><strong>System Optimization:</strong> Applied coding-level optimizations to enhance the system's performance significantly, improving load times and user interactions by 60%.</li>
      </ul>

      <h2>Project Challenges</h2>
      <p>Working closely with GP Bangladesh and its parent company Telenor, I manage the integration of the Direct Operator Billing (DOB) payment system, enhancing the security and efficiency of user transactions.</p>

      <h2>Key Features</h2>
      <ul>
        <li><strong>Secure Transaction Processing:</strong> Management of the DOB payment gateway to ensure secure and reliable billing.</li>
        <li><strong>Enhanced User Experience:</strong> Ongoing optimizations to improve user engagement and system responsiveness.</li>
        <li><strong>Comprehensive Tournament Control:</strong> Updated tools and features that allow for efficient management of all aspects of gaming tournaments.</li>
      </ul>

      <h2>Outcome and Impact</h2>
      <p>The enhancements and diligent management of the Tournament Xoss Games platform have significantly improved its functionality and reliability, leading to increased user satisfaction and a growing reputation as a top-tier platform for competitive gaming.</p>
    `,
    img: require("@/assets/images/projects/tournament-xoss-games.webp"),
  },
  
  {
    "id": 44,
    "title": "ShahelaTrading - ERP Solution",
    "category": "Laravel",
    "tags": ["ERP", "Laravel", "Inventory Management", "Sales Management"],
    "live_link": "https://shahelatrading.com",
    "github_link": "",
    "color": "#91d1f0",
    "date": "01 January 2023 - ∞",
    "desc": `
      <h2>Project Overview</h2>
      <p>ShahelaTrading ERP is a sophisticated enterprise resource planning system tailored for comprehensive inventory and sales management. Developed using Laravel, this ERP solution supports a variety of business processes, enhancing efficiency and facilitating growth.</p>
      <h2>My Role and Responsibilities</h2>
      <p>As a developer, my responsibilities extend beyond mere coding to managing the ERP system's day-to-day operations and implementing new functionalities:</p>
      <ul>
        <li><strong>System Enhancement:</strong> Developing new features such as sales return processing, advanced payment options, and supply chain improvements.</li>
        <li><strong>Financial Operations:</strong> Integrating tools for managing bank checks and generating financial reports, including PDF invoices and sales returns.</li>
        <li><strong>Support and Maintenance:</strong> Providing continuous support and routine maintenance to ensure the system operates seamlessly and stays updated with the latest technological advances.</li>
        <li><strong>Documentation and Reporting:</strong> Creating detailed documentation and reports to support business decisions and operational transparency.</li>
      </ul>
      
      <h2>Outcome and Impact</h2>
      <p>The enhancements I have implemented have significantly improved the functionality and efficiency of the ERP system at ShahelaTrading. The system is now equipped with advanced tools that support dynamic business needs, leading to improved financial management and operational efficiency.</p>
    `,
    "img": require("@/assets/images/projects/erp-1.webp"),
  }

];

const projects = projects_items.map((project) => {
  const title = project.title.replace(/ /g, "-").toLowerCase();
  const permalink = title;
  return {
    ...project,
    permalink,
  };
});

export default projects;
